import * as React from 'react'
import Block from './common/block'
import styled from 'styled-components'
import { breakpointLg, breakpointNotDesktop} from '../design/responsive/breakpoint'
import { borderRadius} from '../design/spacing/margin'
import { yellow} from '../design/colors/brand'
const Container = styled.div`
    padding: 4rem 2rem;
    border-radius: 1.6rem;
    h5 {
        margin-bottom: 1rem;
    }
    @media (max-width: ${breakpointLg}) {
        
       
    }
    @media (min-width: ${breakpointNotDesktop}) {
        
    } 
`;
const Text = styled.div`
    max-width: 820px;
    margin: 0 auto; 
    @media (max-width: ${breakpointLg}) {
       
    }
` 
const Button = styled.a`
    min-width: 19rem;
    @media (max-width: ${breakpointLg}) {
        // padding-left: 2rem!important;
        // padding-right: 2rem!important;
        // min-width: 0;
        // width: 100%;
    }
` 

const CTA = (props: any) => {
    const data = props.data.cta
    const buttonGroup = data.links.length > 1 ? 'cta_buttons ' : ''
  return (
    <>
        <Block className={'cta text-center ' + ' ' + props.background} >
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-8'>
                        <Container  className={'cta__container ' + ' ' + props.color}>
                            <Text className='cta__text'>
                                <h5>{data.title}</h5>
                                <div className='mb-0 h3 f-16'>
                                    {data.content}
                                </div>
                            </Text>
                            <div className={buttonGroup + ' d-flex align-items-center buttons justify-content-center '}>
                            {
                                data.links.map((link: any)=>(
                                    <Button className={'cta__button btn btn-primary ' + link.button_style}  href={(link.url) ? link.url : ''} key={link.id}>{link.text}</Button>
                                ))
                            } 
                            </div> 
                        </Container>    
                    </div>
                </div>
            </div>
        </Block>
    </>
  )
}

export default CTA
