import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import TextMedia from "../components/text-media"
import Title from "../components/title"
import FourthCard from "../components/fourth-card"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import { Helmet } from 'react-helmet'
import IconLink from "../components/icon-link"
import Testimonial from "../components/testimonial"
import CTA from "../components/cta"
import SubMenu from "../components/common/sub-menu"
// markup 
const SocialPrescribing = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  const cta = _data.find((item: any) => item.block === 'cta')
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero');
  const dataIconLink = _data.find((item: any) => item.block === 'icon-link')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  const dataTestimonial = _data.find((item: any) => item.block === 'testimonial')
  return (
    <Layout>
      <Helmet
        title='Social prescribing'
      />
       <SubMenu data={subMenu.data[0]} scrollMenu={true}></SubMenu>
      <Hero background="bg-blue-white" data={hero.data[0]} container={true}></Hero>
      <Title data={dataTitle.data[0]} background="bg-white" ></Title>
      <TextMedia data={dataTextMedia.data[0]}   background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[1]} reverse={true}  background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[2]}    background="bg-white" ></TextMedia>  
      <Title data={dataTitle.data[1]} background="bg-blue-bale" ></Title>
      <IconLink data={dataIconLink.data[0]} background="bg-blue-bale"></IconLink>
      <Title data={dataTitle.data[2]}  background="bg-white"></Title>
      <FourthCard data={fourthCard.data[0]} background="bg-white"  noLink={true}></FourthCard>
      <Title data={dataTitle.data[3]}  background="bg-chalk"></Title>
      <FourthCard data={fourthCard.data[1]} background="bg-chalk" noLink={true} ></FourthCard>
      <CTA background="bg-chalk" size="sm" color='bg-blue' data={cta.data[0]}></CTA>
      <Title data={dataTitle.data[4]}  background="bg-white"></Title>
      <Testimonial vertical={true} data={dataTestimonial.data[0]} background="bg-white"></Testimonial>
      <Title data={dataTitle.data[5]}  background="bg-white" standAlone={true}></Title>
      <Testimonial vertical={false} data={dataTestimonial.data[1]} background="bg-white" ></Testimonial>
      <PreFooter data={dataPrefooter.data[0]}  background="bg-blue"></PreFooter> 
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["social-prescribing"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            sub_menu{
              sub_menu_cta {
                text
                link
              }
              title
              links {
                id
                text
                link
                active
              }
            }
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
              links {
                id
                text
                url
              }
            }
            title_data {
              title
              content
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            cards {
              id
              title
              image
              link
              text
            }
            icons {
              title
              description
              icon_link {
                id
                title
                image
                link
              }
            }
            testimonial {
                id
                author
                address
                position
                content
                image
                bgColor
              }
              cta {
                title
                content
                links {
                  id
                  text
                  url
                  button_style
                }
              }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
                button_style
              }
            }
          }
        }
      }
    }
  }
`
export default SocialPrescribing
